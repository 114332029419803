<template>
  <div>
    <Main-top
      v-if="!categoryLoading"
      class="whitebook-main-top"
      :title="$t('page.white-book')"
      :filterMode="true"
      :categoryData="categoryData"
      :routeCategory="routeCategory"
      :routeTime="routeTime"
    ></Main-top>
    <div v-if="!countLoading">
      <v-container class="inner-container">
        <div
          v-if="showEmptyBlock"
          style="min-height: 400px"
          class="d-flex flex-column justify-center align-center"
        >
          <h2 class="text-3 text-center pa-5" style="color: #00304f">
            {{  dataEmptyText  }}
          </h2>
          <Main-btn v-if="showBackBtn" :to="{ name: 'white-book-index' }">{{ actionBackText }}</Main-btn>
        </div>
        <template v-else>
          <WhiteBookItem v-for="item in list" :key="item.id" :item="item" />
        </template>
      </v-container>
    </div>

    <div v-if="has_next" class="text-center">
      <Main-btn @click="more">{{ $t("action.look-more") }}</Main-btn>
    </div>
  </div>
</template>

<script lang="babel" type="text/babel">
import ecvWaveR from "/src/components/wave/ecvWaveR.vue";
import indexMixins from '@/components/page/index.js'
import indexList from '@/components/page/indexList.js'
import WhiteBookItem from "@/components/whiteBook/whiteBookItem.vue"
export default {
  mixins: [indexMixins, indexList],
  components: {
    ecvWaveR,
    WhiteBookItem
  },
  data: () => ({

  }),
  computed: {
    seoTitle() {
      return this.$store.getters['base/paper_seo_title']
    },
    seoDescription() {
      return this.$store.getters['base/paper_seo_description']
    },
    seoKeywords() {
      return this.$store.getters['base/paper_seo_keywords']
    },
    breadcrumb() {
      return [
        {
          title: this.$t('page.white-book'),
          name: 'white-book-index'
        }
      ]
    },
    indexApi() {
      return this.$api.paper.public.index
    },
    categoryIndexApi() {
      return this.$api.paper.public.categoryIndex
    },
    otherParams() {
      return {
        per: 6
      }
    },
    countApi() {
      return this.$api.paper.public.count
    },
  },
  methods: {

  },
};

</script>

<style lang="sass" type="text/sass">
</style>